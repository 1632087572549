<template>
  <div>

    <AppBar />

    <v-container>
      <v-card
        max-width="600"
        class="mx-auto pb-3"
      >

        <v-card-title>
          <v-tabs
            centered
            v-model="tab"
          >
            <v-tab>
              <v-icon left>mdi-account-group-outline</v-icon>
              Klassenbuch
            </v-tab>
            <v-tab v-if="showCourseBook">
              <v-icon left>mdi-school-outline</v-icon>
              Kursbuch
            </v-tab>
          </v-tabs>
        </v-card-title>

        <v-card-text>

          <v-row>
            <v-col>
              <v-select
                v-model="academicYear"
                :loading="loading"
                :items="academicYears"
                item-text="name"
                label="Schuljahr"
                prepend-icon="mdi-calendar-blank-outline"
                return-object
                dense
                outlined
              />
            </v-col>
          </v-row>

          <v-row v-if="0 === tab">
            <v-col>
              <v-select
                v-model="contextObject"
                :loading="loading"
                :items="schoolClasses"
                :item-text="item => `${item.code} ${item.name || ''}`"
                label="Klasse"
                prepend-icon="mdi-account-group-outline"
                return-object
                dense
                outlined
              />
            </v-col>
          </v-row>

          <template v-else>
            <v-row>
              <v-col>
                <v-select
                  v-model="section"
                  :loading="loading"
                  :items="sections"
                  item-text="name"
                  label="Abschnitt"
                  prepend-icon="mdi-calendar-weekend-outline"
                  return-object
                  dense
                  outlined
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-select
                  v-model="contextObject"
                  :loading="loading"
                  :items="courses"
                  item-text="code"
                  label="Kurs"
                  prepend-icon="mdi-school-outline"
                  return-object
                  dense
                  outlined
                />
              </v-col>
            </v-row>
          </template>

        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            target="_blank"
            :href="link"
            :disabled="loading || !link"
          >
            <v-icon left>mdi-cloud-print-outline</v-icon>
            Öffnen
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-container>

  </div>
</template>


<script>
import { mapState } from 'vuex'
import AppBar from '@/components/app-bar'

export default {
  name: 'BookPage',
  components: {
    AppBar
  },
  data: () => ({
    tab: 0,
    contextObject: null,
    academicYear: null,
    section: null
  }),
  computed: {
    ...mapState('common', [
      'courses',
      'schoolClasses'
    ]),
    loading() {
      return this.$store.getters['common/loading']
    },
    academicYears() {
      return [...this.$store.state.common.academicYears].reverse()
    },
    sections() {
      if (!this.academicYear) return []
      return [...this.$store.getters['schoolCalendar/sectionsByAcademicYear'](this.academicYear)].reverse()
    },
    link() {
      if (!this.contextObject) return null
      return this.contextObject['@id'] + '/book.pdf'
    },
    showWelcomePage() {
      return this.$store.state.common.academicYears.length == 0
    },
    showCourseBook() {
      return !this.$store.state.school?.useWeekReports
    }
  },
  watch: {
    academicYear() {
      if (0 === this.tab) {
        this.loadContextObjectItems()
      } else {
        this.section = this.sections[0] || null
      }
    },
    section() {
      this.loadContextObjectItems()
    },
    async tab() {
      if (0 === this.tab) {
        this.contextObject = this.schoolClasses[0] || null
      } else {
        await this.$store.dispatch('common/fetchCollectionOnce', 'sections')
        this.section = this.sections[0] || null
        this.contextObject = this.courses[0] || null
      }
    }
  },
  async created() {
    await this.$store.dispatch('common/fetchCollectionOnce', 'academicYears')
    if (this.showWelcomePage) {
      this.$router.push({name: 'welcome'})
      return
    }
    this.academicYear = this.academicYears[0] || null
  },
  methods: {
    async loadContextObjectItems() {
      if (!this.academicYear) return
      const { dispatch } = this.$store
      if (0 === this.tab) {
        await dispatch('common/fetchSchoolClassesByAcademicYear', this.academicYear)
        this.contextObject = this.schoolClasses[0] || null
      } else {
        if (!this.section) return
        await dispatch('common/fetchCoursesBySection', this.section)
        this.contextObject = this.courses[0] || null
      }
    }
  }
}
</script>
